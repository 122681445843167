import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import btn_close from '../../../../shared/images/btn_close.svg';

export const stringToDate = (dateStr: string) => {
  const formatter = new Intl.DateTimeFormat('en-us', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'America/Los_Angeles',
  });
  const dateFormat = formatter.formatToParts(new Date(dateStr));

  const year = dateFormat.find(el => el.type === 'year').value;
  const month = dateFormat.find(el => el.type === 'month').value.toUpperCase();
  const day = dateFormat.find(el => el.type === 'day').value;

  return `${day}-${month}-${year}`;
};

export const Sdevider = styled.div`
  padding-bottom: 40px;
  border-bottom: 1px solid black;
  margin-bottom: 40px;
`;

// ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗
// ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║
// ██╔████╔██║██║   ██║██║  ██║███████║██║
// ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║
// ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗
// ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

export const SmodalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;

  > div.contentWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto;
    padding: 40px;
    div.modalstage {
      position: relative;
      z-index: 1;
      width: 100%;
      max-width: 400px;
      min-height: 200px;
      margin: 0 auto;
      background-color: white;

      padding: 40px 30px;

      > button#closeModalButton {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        background-color: transparent;
        outline: none;
        border: none;
        cursor: pointer;
        background-image: url(${btn_close});
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    /* } */
  }
  div.modalbg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.7;
    z-index: -1;
  }
`;

export const ModalContainer = (props: { children: ReactNode; onclose: () => void }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <SmodalContainer className="modal-container">
      <div className="contentWrapper">
        <div className="modalstage">
          {props.children}
          <button id="closeModalButton" onClick={() => props.onclose()} />
        </div>
      </div>
      <div className="modalbg" onClick={() => !!props.onclose && props.onclose()}></div>
    </SmodalContainer>
  );
};

// ███████╗██╗██████╗ ███████╗    ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗
// ██╔════╝██║██╔══██╗██╔════╝    ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║
// ███████╗██║██║  ██║█████╗      ██╔████╔██║██║   ██║██║  ██║███████║██║
// ╚════██║██║██║  ██║██╔══╝      ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║
// ███████║██║██████╔╝███████╗    ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗
// ╚══════╝╚═╝╚═════╝ ╚══════╝    ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

const SmoreModal = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.7); */
  z-index: 1;
  padding-left: 54px;

  height: 100%;
  > div.contentwrapper {
    height: 100%;
    /* background-color: #f00; */

    max-width: 690px;
    overflow: auto;
    > div {
      position: relative;
      background-color: #fff;
      width: 100%;
      min-height: 100%;
      /* height: 100%; */
      padding: 100px 30px;
      /* overflow: auto; */
      > button {
        position: absolute;
        top: 40px;
        right: 30px;
      }
    }
  }

  @media (min-width: 800px) {
    & {
      /* padding-left: 260px; */
      padding-left: 0;
      > div.contentwrapper {
        margin: 0 auto;
        > div {
        }
      }
    }
  }
`;

const ScloseButton = styled.button`
  width: 28px;
  height: 31px;
  position: relative;
  border: 0;
  background-color: white;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 13px;
    left: 0;
    width: 28px;
    height: 5px;
    background-color: #33cc33;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &:after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Smodalback = styled.div<{ sidecolor: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;

  &:after {
    content: '';
    width: 50%;
    height: 100%;
    background-color: ${props => props.sidecolor};
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const SideModal = (props: {
  children: React.ReactNode;
  color: string;
  onClose: () => void;
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      if (document.querySelectorAll('.sidemodal').length !== 1) {
        document.body.removeAttribute('style');
      }
    };
  }, []);
  return (
    <SmoreModal className="sidemodal">
      <Smodalback
        className="modalback"
        onClick={() => {
          console.log('clicked');
          props.onClose();
        }}
        sidecolor={props.color}
      />
      <div className="contentwrapper">
        <div>
          <ScloseButton onClick={() => props.onClose()} />
          {props.children}
        </div>
      </div>
    </SmoreModal>
  );
};

// ███████╗██╗     ███████╗███╗   ███╗███████╗███╗   ██╗████████╗███████╗
// ██╔════╝██║     ██╔════╝████╗ ████║██╔════╝████╗  ██║╚══██╔══╝██╔════╝
// █████╗  ██║     █████╗  ██╔████╔██║█████╗  ██╔██╗ ██║   ██║   ███████╗
// ██╔══╝  ██║     ██╔══╝  ██║╚██╔╝██║██╔══╝  ██║╚██╗██║   ██║   ╚════██║
// ███████╗███████╗███████╗██║ ╚═╝ ██║███████╗██║ ╚████║   ██║   ███████║
// ╚══════╝╚══════╝╚══════╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const skbouncedelay = keyframes`
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

const SloadingComp = styled.div`
  width: 60px;
  margin: 0 auto;
  font-size: unset;

  > div {
    width: 15px;
    height: 15px;
    margin: 0 2px;
    background-color: #33ff33;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ${skbouncedelay} 1s infinite ease-in-out both;
    animation: ${skbouncedelay} 1s infinite ease-in-out both;
  }
  > div.bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  > div.bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
`;

export const LoadingComp = () => (
  <SloadingComp className="loading-comp">
    <div className="bounce1"></div>
    <div className="bounce2"></div>
    <div className="bounce3"></div>
  </SloadingComp>
);

const SelemButton = styled.div`
  position: relative;
  > div.loading-comp {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  > button {
    width: 100%;
    height: 48px;
    border: 0;
    border-radius: 24px;
    background-color: #33cc33;
    &.black {
      background-color: black;
    }
    &.grey {
      background-color: #cccccc;
    }
    color: white;
    font-family: 'Hyundai Sans Head-Bold';
    font-size: 22px;
    &:disabled {
      background-color: #666666;
    }
    &:focus {
      background-color: #44ee44;
      &.black {
        background-color: #333333;
      }
    }
  }
`;

export const ElemButton = (props: {
  children: React.ReactNode;
  isLoading?: boolean;
  black?: boolean;
  grey?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}) => {
  let classname = '';
  if (props.black) {
    classname = 'black';
  } else if (props.grey) {
    classname = 'grey';
  }
  return (
    <SelemButton className="elem_button">
      {props.isLoading ? <LoadingComp /> : null}
      <button
        onClick={() => props.onClick && props.onClick()}
        disabled={props.isLoading || props.disabled}
        className={classname}
        type={props.type ? props.type : 'button'}
      >
        {props.isLoading ? '' : props.children}
      </button>
    </SelemButton>
  );
};

export const Scheckbox = styled.input`
  // display: none;
  appearance: none;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */

  position: relative;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border: none;
  margin-right: 10px;

  &:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    width: 32px;
    height: 32px;
    background-color: #e4e4e4;
  }
  &:after {
    content: '';
    width: 10px;
    height: 10px;
    border: 1px solid #cccccc;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &:focus:after {
    border: 1px solid #33cc33;
  }

  &:checked:focus:before {
    background-color: #efefef;
  }

  &:checked:after {
    width: 10px;
    height: 10px;
    border: none;
    background-color: #33cc33;
    top: 11px;
    left: 11px;
  }
  &:disabled:before {
    background-color: #cccccc;
  }
  &:disabled:after {
    background-color: #e4e4e4;
  }
`;

const SmailtoHello = styled.div`
  > span {
    margin-right: 8px;
  }
  > a {
    display: inline-block;
    width: 150px;
    height: 48px;
    border-radius: 24px;
    color: white;
    background-color: #33cc33;
    text-align: center;
    line-height: 48px;
    font-family: 'Hyundai Sans Head-Bold';
    font-size: 22px;
  }
`;

export const MailtoHello = () => {
  return (
    <SmailtoHello>
      <span className="body18">If you are startup,</span>
      <a target="_blank" href="mailto: Hello@mobilityinnovatorsforum.com" rel="noreferrer">
        Contact us
      </a>
    </SmailtoHello>
  );
};
