import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { pages } from '../../global_variables';

// ██╗  ██╗███████╗ █████╗ ██████╗ ███████╗██████╗
// ██║  ██║██╔════╝██╔══██╗██╔══██╗██╔════╝██╔══██╗
// ███████║█████╗  ███████║██║  ██║█████╗  ██████╔╝
// ██╔══██║██╔══╝  ██╔══██║██║  ██║██╔══╝  ██╔══██╗
// ██║  ██║███████╗██║  ██║██████╔╝███████╗██║  ██║
// ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚═╝  ╚═╝

const barLength = 28; // px
const barThickness = 5; // px
const trTime = 0.2; // s

const SmenuIcon = styled.div<{ isOpen: boolean; isWhiteBg: boolean }>`
  width: 28px;
  height: 31px;

  cursor: pointer;

  > div {
    position: relative;
    top: 13px;
    margin: 0 auto;
    width: ${barLength}px;
    height: ${barThickness}px;
    border-radius: 1px;
    background-color: ${props => (props.isOpen || props.isWhiteBg ? '#33cc33' : '#000')};
    opacity: 1;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: -13px;
      left: 0;
      width: ${barLength}px;
      height: ${barThickness}px;
      background-color: ${props => (props.isOpen || props.isWhiteBg ? '#33cc33' : '#000')};
      color: white;
      transition-property: background-color, color, transform, top;
      transition-duration: ${trTime}s, ${trTime}s, ${trTime / 2}s, ${trTime / 2}s;
      transition-delay: 0s, 0s, 0s, ${trTime / 2}s;
    }

    &:after {
      top: 13px;
    }
  }

  ${props =>
    props.isOpen &&
    css`
      position: fixed;
      top: 40px;
      right: 30px;
      > div {
        background-color: #000;

        &:before,
        &:after {
          top: 0;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          transition-delay: 0s, 0s, ${trTime / 2}s, 0s;
        }

        &:after {
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    `}
`;

export const MenuIcon = (props: {
  isOpen: boolean;
  isWhiteBg: boolean;
  setOpenState: () => void;
}) => (
  <SmenuIcon
    id="menuicon"
    isWhiteBg={props.isWhiteBg}
    isOpen={props.isOpen}
    onClick={() => props.setOpenState()}
  >
    <div />
  </SmenuIcon>
);

const Snav = styled.nav`
  width: 100%;
  height: 100%;
  background-color: black;
  overflow: auto;
  /* padding: 100px 80px; */
  > a {
    font-family: 'Hyundai Sans Head-Bold';
    font-size: 26px;
    /* margin-bottom: 40px; */
    display: block;
    color: white;
  }

  > a#toregisterbutton {
    color: #33cc33;
  }

  > a.current {
    border-bottom-style: solid;
    border-bottom-width: 3px;
    width: fit-content;
  }
`;

export const Nav = (props: { clicked: () => void }) => {
  const loca = useLocation();
  const current = loca.pathname.split('/')[1];
  return (
    <Snav>
      <Link
        onClick={() => props.clicked()}
        to={{ pathname: '/' }}
        id="tohomebutton"
        className={current === pages.HOME ? 'current' : ''}
      >
        HOME
      </Link>
      <Link
        onClick={() => props.clicked()}
        to={{ pathname: '/about' }}
        className={current === pages.ABOUT ? 'current' : ''}
      >
        ABOUT
      </Link>
      <Link
        onClick={() => props.clicked()}
        to={{ pathname: '/schedule' }}
        className={current === pages.SCHEDULE ? 'current' : ''}
      >
        SCHEDULE
      </Link>
      <Link
        onClick={() => props.clicked()}
        to={{ pathname: '/speakers' }}
        className={current === pages.SPEAKERS ? 'current' : ''}
      >
        SPEAKERS
      </Link>
      <Link
        onClick={() => props.clicked()}
        to={{ pathname: '/ignite' }}
        className={current === pages.IGNITE ? 'current' : ''}
      >
        IGNITE
      </Link>
      {/* <Link
        onClick={() => props.clicked()}
        to={{ pathname: '/archive' }}
        className={current === pages.ARCHIVE ? 'current' : ''}
      >
        ARCHIVE
      </Link> */}
      <Link
        onClick={() => props.clicked()}
        to={{ pathname: '/channel' }}
        className={current === pages.CHANNEL ? 'current' : ''}
      >
        CHANNEL
      </Link>
    </Snav>
  );
};
