import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import cradle_logo from '../../../../shared/images/cradle_logo_new.svg';

// Modal.setAppElement('#modalroot');

const Sfooter = styled.footer`
  /* height: 230px; */
  width: 100%;
  max-width: 1120px;

  grid-row-start: 2;
  grid-row-end: 3;

  > div {
    padding: 25px 0;
    border-top-width: 2px;
    border-top-style: solid;
    border-top-color: black;

    > img {
      margin-bottom: 25px;
    }

    > div > span {
      font-family: 'Hyundai Sans Text-Regular';
      font-size: 12px;
    }
    > div > a > span {
      font-family: 'Hyundai Sans Text-Regular';
      font-size: 14px;
      /* line-height: 12px; */
    }
  }
`;

const Footer = () => (
  <Sfooter>
    <div>
      <img src={cradle_logo} />
      <div>
        <span>© 2021 Hyundai Motor Group.</span>
        <br />
        <br />
        <Link to={{ pathname: '/termsofuse' }}>
          <span>Terms of Use</span>
        </Link>
        <br />
        <Link to={{ pathname: '/privacypolicy' }}>
          <span>Privacy Policy</span>
        </Link>
        <br />
        <a target="_blank" href="mailto:info@mobilityinnovatorsforum.com" rel="noreferrer">
          <span>Contact</span>
        </a>
      </div>
    </div>
  </Sfooter>
);

export default Footer;
