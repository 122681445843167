import loadable from '@loadable/component';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useLocation } from 'react-router';
import { Route, Routes } from 'react-router-dom';

import { isWhiteBg, setNoHead } from '../global_variables';
import Footer from './components/Footer';
import Header from './components/Header';

Modal.setAppElement('#root');

const Motionbg = loadable(
  () => import(/* webpackChunkName: "c_motionbg" */ './components/Motionbg'),
);
const Main = loadable(() => import(/* webpackChunkName: "p_main" */ './pages/Main'));
const About = loadable(() => import(/* webpackChunkName: "p_about" */ './pages/About'));
const Ignite = loadable(() => import(/* webpackChunkName: "p_ignite" */ './pages/Ignite'));
const Archive = loadable(() => import(/* webpackChunkName: "p_archive" */ './pages/Archive'));
const Channel = loadable(() => import(/* webpackChunkName: "p_channel" */ './pages/Channel'));
const ChannelCategory = loadable(
  () => import(/* webpackChunkName: "p_channel_category" */ './pages/Channel-category'),
);
const ChannelContent = loadable(
  () => import(/* webpackChunkName: "p_channel_category" */ './pages/Channel-content'),
);
const Privacy = loadable(() => import(/* webpackChunkName: "p_privacy" */ './pages/Privacy'));
const Schedule = loadable(() => import(/* webpackChunkName: "p_schedule" */ './pages/Schedule'));
const Speakers = loadable(() => import(/* webpackChunkName: "p_speakers" */ './pages/Speakers'));
const Terms = loadable(() => import(/* webpackChunkName: "p_terms" */ './pages/Terms'));

const Survey = loadable(() => import(/* webpackChunkName: "p_terms" */ './pages/Survey'));

const App = () => {
  const loca = useLocation();
  const currentPageName = loca.pathname.split('/')[1];

  const [locationHistory, setLocationHistory] = useState<string[]>([]);

  useEffect(() => {
    locationHistory.push(loca.pathname);
    while (locationHistory.length > 3) locationHistory.shift();
    setLocationHistory(locationHistory);
  }, [loca, locationHistory]);

  return (
    <div id="bgcontainer" className={isWhiteBg(currentPageName) ? 'mono' : ''}>
      <Motionbg isWhite={isWhiteBg(currentPageName)} />
      <div id="contentscontainer">
        <div id="contents">
          {setNoHead(currentPageName) ? null : <Header mono={isWhiteBg(currentPageName)} />}
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/speakers" element={<Speakers />} />
            {/* <Route path={['/ignite', '/ignite/:usertoken']} element={<Ignite />} /> */}
            <Route path="/ignite" element={<Ignite />} />
            <Route path="/ignite/:usertoken" element={<Ignite />} />
            <Route path="/archive" element={<Archive />} />
            <Route path="/channel" element={<Channel />} />
            <Route path="/channel/:category" element={<ChannelCategory />} />
            <Route path="/channel/:category/:contentid" element={<ChannelContent />} />
            <Route path="/termsofuse" element={<Terms />} />
            <Route path="/privacypolicy" element={<Privacy />} />
            {/* <Route path={['/survey', '/survey/:userid']} element={<Survey />} /> */}
            <Route path="/survey" element={<Survey />} />
            <Route path="/survey/:userid" element={<Survey />} />
            <Route path="/" element={<Main />} />
            {/* 404 redirect required */}
          </Routes>
        </div>
        <div id="footerpush" />
      </div>
      <Footer />
    </div>
  );
};

export default App;
