import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import mif_logotype from '../../../../shared/images/mif_logotype.svg';
import mif_symbol_green from '../../../../shared/images/mif_symbol_green.svg';
import mif_symbol_white from '../../../../shared/images/mif_symbol_white.svg';
import { MenuIcon, Nav } from './Header-comp';

export const SmainLogo = styled(Link)<{ $mono: boolean }>`
  display: block;
  position: relative;
  width: 160px;
  background-image: ${props =>
    props.$mono ? `url(${mif_symbol_green})` : `url(${mif_symbol_white})`};
  background-size: contain;
  background-repeat: no-repeat;

  &:before {
    content: '';
    position: absolute;
    width: 66%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-image: url(${mif_logotype});
    background-position: left bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 110%;
  }
`;

const SmenuContainer = styled.div<{ isOpen: boolean }>`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* padding-left: 54px; */
`;

const Header: React.FunctionComponent<{ mono: boolean }> = props => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }
  }, [isOpen]);

  return (
    <header>
      <div id="navcontainer">
        <SmainLogo id="mainlogo" to={{ pathname: '/' }} $mono={props.mono} />
        <MenuIcon isWhiteBg={props.mono} isOpen={isOpen} setOpenState={() => setOpen(!isOpen)} />
        <SmenuContainer id="menucontainer" isOpen={isOpen}>
          <Nav clicked={() => setOpen(false)} />
        </SmenuContainer>
      </div>
    </header>
  );
};

export default Header;
