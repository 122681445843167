import '../../../shared/css/globalStyle.scss';
import './style.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { ModalProvider } from 'react-modal-hook';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { ScrollToTop } from './components/Comps';

const rootElement = document.getElementById('root');

const debounce = (fn: any) => {
  let frame: any;
  return (...params: any[]) => {
    if (frame) cancelAnimationFrame(frame);
    frame = requestAnimationFrame(() => fn(...params));
  };
};

const storeScroll = () => {
  if (window.scrollY > 0) {
    rootElement.classList.add('nottop');
  } else {
    rootElement.classList.remove('nottop');
  }
};

document.addEventListener('scroll', debounce(storeScroll), { passive: true });
storeScroll();

ReactDOM.render(
  <BrowserRouter>
    <ModalProvider>
      <ScrollToTop />
      <App />
    </ModalProvider>
  </BrowserRouter>,
  rootElement,
);
