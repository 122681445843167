export const pages = {
  REGISTER: 'register',
  HOME: '',
  ABOUT: 'about',
  SCHEDULE: 'schedule',
  SPEAKERS: 'speakers',
  IGNITE: 'ignite',
  TERMSOFUSE: 'termsofuse',
  PRIVACYPOLICY: 'privacypolicy',
  CHANNEL: 'channel',
  ARCHIVE: 'archive',
  SURVEY: 'survey',
};

export const isWhiteBg = (currentPageName: string) => {
  return (
    currentPageName === pages.TERMSOFUSE ||
    currentPageName === pages.PRIVACYPOLICY ||
    currentPageName === pages.CHANNEL ||
    currentPageName === pages.SURVEY ||
    currentPageName === pages.REGISTER
  );
};

export const removeRegisterButtonStatus = (currentPageName: string) => {
  return (
    currentPageName === pages.TERMSOFUSE ||
    currentPageName === pages.PRIVACYPOLICY ||
    currentPageName === pages.SURVEY ||
    currentPageName === pages.REGISTER
  );
};

export const setNoHead = (currentPageName: string) => {
  return (
    // currentPageName === pages.TERMSOFUSE ||
    currentPageName === pages.SURVEY
  );
};
